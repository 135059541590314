root {
  --main: rgb(255, 165, 0);
  --secondary: #562000b0;
  --lightBlack: #2f2f2f;
}

.top-container {
  width: 100%;
  background-color: transparent;
  color: white;
  padding-bottom: 50px;
  padding-top: 100px;
}

.box-shadow {
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.15);
}
.css-margin-man-cu {
  margin: 70px;
}
.cu-right-top-policy-container {
  text-align: center;
  color: white;
  padding: 10px 35px;
}
.cu-m-padding-17 {
  padding: 70px 150px 70px 0;
}
.border-left-main-color {
  border-left: 4px solid var(--main);
}
.social-icons {
  background-color: #2f2f2f;
  color: white;
  margin-right: 15px;
  padding: 8px;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .top-container {
    padding-bottom: 0;
  }
  .cu-m-padding-17{
    padding: 30px 0;
  }
}
