.blogs-css-pad {
  padding: 50px 120px;
}
.hire-css-73 {
  background: rgb(47, 47, 47);
  padding: 20px;
  color: white;
}
.css-mp-blog {
  padding: 45px 65px 0px;
}

@media only screen and (max-width: 1200px) {
    .css-mp-blog {
        padding: 0px;
      }
  .blogs-css-pad{
    padding: 35px 15px;
  }
}
