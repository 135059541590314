:root {
  --main: #167ac6;
  --secondary: #000000;
  --lightBlack: #2f2f2f;
}
.top-home-container {
  width: 100%;
  background-color: transparent;
  color: white;
  padding-top: 100px;
  padding-bottom: 50px;
}
.bg-gradient-main {
  /* background-image: radial-gradient(at center left, #167ac6 17%, #000000 47%); */
  background-image: radial-gradient(
    at center left,
    var(--main) 0%,
    var(--secondary) 77%
  );
}
.left-home-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-right-top-heading {
  font-size: 55px;
  color: white;
  font-weight: 600;
}
.right-home-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
}
.elementor-21329 {
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
}
.navbar-solid .nav-link {
  color: black !important;
  padding: 20px;
  border-bottom: 5px solid transparent;
  font-weight: 500;
}
.navbar-solid .nav-link:hover {
  border-bottom: 5px solid var(--main);
}
.navbar-transparent .nav-link {
  color: white !important;
  padding: 20px;
  border-bottom: 5px solid transparent;
  font-weight: 500;
}
.navbar-transparent .nav-link:hover {
  border-bottom: 5px solid var(--main);
  cursor: pointer;
}
.nav-item {
  margin: 0 15px;
}
.companies-heading {
  color: #16222c;
  font-family: "Poppins", Sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
.left-companies-section {
  padding: 70px 60px;
}
.com-grid-1-block {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 7px 10px rgb(0 0 0 / 18%);
  margin-bottom: 20px;
  height: 75px;
}
.com-grid-1-block img {
  width: 80px;
  height: 60px;
}
.com-grid-middle-block {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 7px 10px rgb(0 0 0 / 18%);
  background-image: url("../../assets/comapniessLogo/dotted-world.png");
}
.navbar-toggler {
  background-color: white !important;
}
.clients-no {
  color: var(--main);
  font-size: 95px;
  font-weight: 500;
  line-height: 1;
}
.cl-lettr {
  font-family: "Poppins", Sans-serif;
  font-size: 24px;
  font-weight: 400;
}
.cl-descr {
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}
.dd-tlt {
  color: #2f2f2f;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 27px;
  margin-bottom: 45px;
}
.h-curve-img {
  width: 285px;
  height: 17px;
  margin-top: 10px;
}
.our-ser-tlt {
  color: var(--secondary);
  font-family: "Poppins", Sans-serif;
  font-size: 34px;
  font-weight: 500;
  line-height: 45px;
}
.ser-desc {
  color: var(--e-global-color-text);
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 30px;
}
.heading-title {
  color: var(--main);
  font-family: "Poppins", Sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
}
.border-gray-1 {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #00000033;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 10px;
  border-radius: 10px;
}
.element-1 {
  text-align: center;
  color: #2f2f2f;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 20px;
}
.white-grad-box {
  background-image: url("../../assets/images/min_ten_bg.png");
  height: 75px;
  color: black;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.sec-developer-container {
  padding: 120px 100px;
}
.dev-card {
  background-color: #f2f7f2;
  padding: 15px 0px 0px 15px;
  border-radius: 15px;
}
.accordion-button {
  background-color: white !important;
  box-shadow: none !important;
  border: none;
}
.accordion-item {
  border: none !important;
}
.hfp-container {
  padding: 100px 50px;
}
.tech-card {
  background-color: #ffffff;
  padding: 16px 20px 16px 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 15px 0px 0px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 0px;
  min-height: 315px;
  max-height: -webkit-fill-available;
}
.sky-blue-container {
  clip-path: polygon(0 0, 100% 0, 100% 99%, 3% 100%);
  padding: 10px;
  /* background-color: #00d7f7; */
  background-color: var(--main);
  box-shadow: 7px 7px 14px 0px rgba(0, 0, 0, 0.15);
  margin: 15px 0;
  color: white;
  font-size: 15px;
  font-weight: 600;
  text-align: right;
}

.blue-container {
  clip-path: polygon(0 0, 99% 0, 100% 99%, 0 100%);
  padding: 10px 10px 10px 25px;
  background-color: var(--secondary);
  box-shadow: 7px 7px 14px 0px rgba(0, 0, 0, 0.15);
  margin: 15px 0;
  color: white;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  position: relative;
}
.foc-type-cont {
  padding: 0 100px;
}
.w-11011 {
  width: 38%;
}

.w-1011 {
  width: 39%;
}
.w-2011 {
  width: 37%;
}
.w-3011 {
  width: 35%;
}
.w-4011 {
  width: 33%;
}
.w-5011 {
  width: 31%;
}
.w-6011 {
  width: 29%;
}
.w-1022 {
  width: 61%;
}
.w-0011 {
  width: 39%;
}
.w-0022 {
  width: 61%;
}
.manage-p-cp7 {
  padding: 50px 0 50px 30px;
}
.css-w-7 {
  width: 210px;
  height: 290px;
}
.gdpr-cont {
  background-image: url("../../assets/images/protections/gdpr.png");
}
.nda-cont {
  background-image: url("../../assets/images/protections/nda.png");
}
.network-cont {
  background-image: url("../../assets/images/protections/networking.png");
}
.contract-cont {
  background-image: url("../../assets/images/protections/contract.png");
}
.restricted-cont {
  background-image: url("../../assets/images/protections/restricted.png");
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.css-478-th {
  color: var(--main);
  font-weight: 600;
  font-size: 55px;
}
.css-mp-78s {
  padding: 0 65px;
}
.css-mdg-1-mp {
  padding: 0 100px;
}
.service-lr-box {
  background: #e2f3ff;
  padding: 24px 20px 40px 20px;
  height: 635px;
  /* color: white; */
}
.slide-box {
  background-color: white;
  color: var(--lightBlack);
  padding: 15px;
  margin: 0 15px;
  text-align: center;
  min-height: 335px;
}
.client-city {
  width: 68px;
  text-align: center;
  margin: auto;
  margin-top: -41px;
  background: white;
  border-radius: 50%;
  padding: 5px;
  border-radius: 50%;
}
.slick-slide {
  padding-top: 30px !important;
}
.slide-box img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 50%;
  margin: auto;
  margin-top: 10px;
}
.slick-arrow {
  display: none !important;
}
.sider-conatainer5-mp {
  padding: 0 70px;
}

@media only screen and (max-width: 1200px) {
 
  .sider-conatainer5-mp {
    padding: 0;
  }
  .css-mdg-1-mp {
    padding: 0;
  }
  .foc-type-cont {
    padding: 0;
  }
  .hfp-container {
    padding: 50px 13px;
  }
  .our-ser-tlt {
    font-size: 25px;
  }
  .sec-developer-container {
    padding: 22px;
  }
  .spacing-40 {
    margin-bottom: 40px;
  }
  .css-mp-78s {
    padding: 0 5px;
  }
  .css-478-th {
    color: var(--main);
    font-weight: 600;
    font-size: 30px;
  }
  .home-right-top-heading {
    font-size: 33px;
    margin: auto;
  }
  .h-curve-img {
    width: 175px;
    height: 17px;
    margin-top: 10px;
  }
  .left-companies-section {
    padding: 20px 5px;
  }
}
