@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
root {
  --main:rgb(255, 165, 0);
  --secondary:#562000b0
}

* {
  padding: 0;
  margin: 0;
  font-family: "Poppins";
}
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#site-wrapper {
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
}
html {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none;
}

container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }

  .header-select-cat {
    width: 54%;
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
  }
}
.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.header-user-logo {
  background: white;
  color: black;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.dropdown-toggle::after {
  display: none;
}
.navbar-transparent {
  background: transparent !important;
  position: absolute;
  width: 100%;
  box-shadow: 0 0 15px rgba(0,0,0,.1);
}

.navbar-solid {
  position: fixed;
  background: white !important;
  width: 100%;
  box-shadow: 0 0 15px rgba(0,0,0,.1) !important;
}
.revealX {
  position: relative;
  transform: translateX(150px);
  opacity: 0;
  transition: 1s all ease;
}

.revealX.activeX {
  transform: translateX(0);
  opacity: 1;
}

.revealY {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.jcc {
  justify-content: center;
}
.jcsb {
  justify-content: space-between;
}
.jcs {
  justify-content: start;
}
.jce {
  justify-content: end;
}
.aic {
  align-items: center;
}
.ais {
  align-items: start;
}
.aie {
  align-items: end;
}
.fdc {
  flex-direction: column;
}
.fw-500{
  font-weight: 500;
}
.fw-600{
  font-weight: 600;
}
.custom-btn-component {
  padding: 10px 20px;
  border: 1px solid var(--secondary);
  border-radius: 5px;
}
.hidden-in-responsive{
  display: block;
}
.w-33-w-100{
  width: 33%;
}
.css-mhpadd{
  padding: 6px 60px;
}
.css8-react-scroll a{
  text-decoration: none;
}

@media only screen and (max-width: 1200px) {
  .css-mhpadd{
    padding: 6px 25px;
  }
  .custom-btn-component {
    padding: 5px 5px;
    border: 1px solid var(--main);
    border-radius: 5px;
  }
  .hidden-in-responsive{
    display: none;
  }
  .w-33-w-100{
    width: 100%;
  }
}
