:root {
  --main: #167ac6;
  --secondary: #000000;
  --white: white;
  --light-blue: lightBlue;
}

.get-in-touch-container {
  background-image: url("../../assets/images/get_in_touch.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scaleX(-1);
  min-height: 100vh;
}
.reverse-flip-text {
  transform: scaleX(-1);
}
.get-in-touch-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(22, 122, 198, 0.5); /* Adjust the color and opacity as needed */
}
.content-heading {
  color: var(--light-blue);
  font-family: "Poppins", Sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 50px;
  margin-bottom: 30px;
}
.content-description {
  font-family: "Poppins", Sans-serif;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 30px;
}
.fs-28{
  font-size: 28px;
}
.fs-20{
  font-size: 20px;
}
.content-subDescription {
  font-size: 15px;
}
.git-left-container {
  padding: 44px 55px 50px 90px;
}
.git-right-container{
    background-color: white;
    color: #000000;
    text-align: center;
    padding: 20px;
    margin: 30px;
    border-radius: 10px;
}

@media only screen and (max-width: 1200px) {
  .git-right-container{
    margin: 17px 5px;
}
.git-left-container{
  padding: 20px;
}

}